export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired


    const mainMenuNav = document.getElementById('main-menu-toggle')

    mainMenuNav.addEventListener('shown.bs.collapse', event => {
      $('body').addClass('scroll-lock');
    });

    mainMenuNav.addEventListener('hidden.bs.collapse', event => {
      $('body').removeClass('scroll-lock');
    });
  },
};
